@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

::selection {
  background: #ff9bcf;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  background: #eae2ff;
}

body::-webkit-scrollbar-thumb {
  background-color: #202020;
}

.animated-gradient-background {
  background: linear-gradient(90deg, #ff9bcf, #9c9aff, #71e6e8);
  background-size: 300% 300%;
  animation: gradient-animation 4s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient-text {
  background: linear-gradient(90deg, #ff9bcf, #9c9aff, #71e6e8);
  background-size: 300% 300%;
  animation: gradient-animation 4s ease infinite;

  /* Vendor Prefixes for Compatibility */
  -webkit-background-clip: text; /* Safari, Chrome */
  -moz-background-clip: text; /* Firefox (falls unterstützt) */
  background-clip: text; /* Standarddefinition */

  -webkit-text-fill-color: transparent; /* Safari, Chrome */
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
